import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["row"];
  toggle(event) {
    if (event.target.checked) {
      this.rowTarget.classList.add("hidden");
    } else {
      this.rowTarget.classList.remove("hidden");
    }
  }
}
