import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.activeImage = null;
  }
  toggleStyle(card) {
    card.classList.toggle("border-primary");
  }
  toggleFilter(e) {
    let clicked = e.target.getAttribute("data-image");
    let cards = this.element.querySelectorAll(".connector-detail");
    let bars = this.element.querySelectorAll("[data-bar]");

    if (this.activeImage === clicked) {
      this.toggleStyle(e.target);
      this.resetBars(bars);
      cards.forEach((card) => {
        card.style.display = "block";
      });
      this.activeImage = null;
    } else {
      this.toggleStyle(e.target);
      this.resetBars(bars);
      this.activateBar(clicked);

      cards.forEach((card) => {
        let images = card.getAttribute("data-image").split(",");
        if (images.includes(clicked)) {
          card.style.display = "block";
        } else {
          card.style.display = "none";
        }
      });

      if (this.activeImage !== null) {
        let previousActive = this.element.querySelector(
          `[data-image="${this.activeImage}"]`
        );
        if (previousActive) {
          this.toggleStyle(previousActive);
        }
      }

      this.activeImage = clicked;
    }
  }

  resetBars(bars) {
    bars.forEach((bar) => {
      bar.style.width = "0";
    });
  }

  activateBar(imageNumber) {
    const bar = this.element.querySelector(`[data-bar="${imageNumber}"]`);
    if (bar) {
      bar.style.width = "100%";
    }
  }
}
